import {ILogger} from "aurelia";
import { HttpClientConfiguration, IHttpClient } from '@aurelia/fetch-client';
import {Joueur} from "../../interfaces/joueur";

export class StorageService {

    public storage: any;

    public defaultResponses = {
        "alert1": {
            "bonus": 0,
            "actions": [
                {
                    "id": 3,
                    "eco": 50,
                    "confort": 50,
                    "planete": 150
                },
                {
                    "id": 4,
                    "eco": 0,
                    "confort": 100,
                    "planete": 200
                },
                {
                    "id": 5,
                    "eco": 50,
                    "confort": 150,
                    "planete": 250
                }
            ]
        }
    }


    public constructor(
    )
    {
        this.storage = sessionStorage;
    }

    /*
     * Store Player Responses
     */
    public storePlayerResponses(body: any)
    {
        const playerResponsesString = JSON.stringify(body);
        this.storage.setItem('playerResponses', playerResponsesString);
        return true;
    }

    /*
     * Store Player Responses
     */
    public getPlayerResponses()
    {
        const playerReponsesString = this.storage.getItem('playerResponses');
        if (playerReponsesString) {
            return JSON.parse(playerReponsesString);
        } else {

        }
        return null;
    }

    public deletePlayerResponse()
    {
        this.storage.removeItem('playerResponses');
        return true;
    }

    public storePlayer(joueur: Joueur)
    {
        this.storage.setItem('player', JSON.stringify(joueur));

        return true;
    }

    public getPlayer()
    {
        const joueurString = this.storage.getItem('player');
        if (joueurString) {
            return JSON.parse(joueurString);
        }
        return null;
    }

    public storeTimer(timerId: any)
    {
        this.storage.setItem('timerId', timerId);

        return true;
    }
    public getTimer()
    {
        return this.storage.getItem('timerId');
    }
    public deleteTimer()
    {
        this.storage.removeItem('timerId');
        return true;
    }
    public deletePlayer()
    {
        this.storage.removeItem('player');
        return true;
    }

    public addFakeIds(fakeAlertId: any) {
        let fakeIds = this.storage.getItem('fakeAlertId');
        if (!fakeIds) {
            fakeIds = [];
        } else {
            fakeIds = JSON.parse(fakeIds);
        }
        if (!fakeIds.includes(fakeAlertId)) {
            fakeIds.push(fakeAlertId);
        }
        this.storage.setItem('fakeAlertId', JSON.stringify(fakeIds));
    }
    public getFakeIds() {
        const fakeIds = this.storage.getItem('fakeAlertId');
        if (!fakeIds) {
            return [];
        }
        return JSON.parse(fakeIds);
    }

    public deleteFakeIds() {
        this.storage.removeItem('fakeAlertId');
        return true;
    }
    public addRunningFakeIds(fakeAlertId: any) {
        let fakeIds = this.storage.getItem('runningFakeAlertId');
        if (!fakeIds) {
            fakeIds = [];
        } else {
            fakeIds = JSON.parse(fakeIds);
        }
        if (!fakeIds.includes(fakeAlertId)) {
            fakeIds.push(fakeAlertId);
        }
        this.storage.setItem('runningFakeAlertId', JSON.stringify(fakeIds));
    }
    public getRunningFakeIds() {
        const fakeIds = this.storage.getItem('runningFakeAlertId');
        if (!fakeIds) {
            return [];
        }
        return JSON.parse(fakeIds);
    }
    public deleteRunningFakeId(fakeAlertId: any) {
        let fakeIds = this.storage.getItem('runningFakeAlertId');
        if (!fakeIds) {
            fakeIds = [];
        } else {
            fakeIds = JSON.parse(fakeIds);
        }
        if (fakeIds.includes(fakeAlertId)) {
            fakeIds.splice(fakeIds.indexOf(fakeAlertId), 1);
        }
        this.storage.setItem('runningFakeAlertId', JSON.stringify(fakeIds));
    }
    public deleteRunningFakeIds() {
        this.storage.removeItem('runningFakeAlertId');
        return true;
    }
    public setGameRunning(status: boolean) {
        this.storage.setItem('gameStatus', JSON.stringify(status));
    }
    public getGameRunning() :boolean {
        const status = this.storage.getItem('gameStatus');
        if (!status) {
            return false;
        }
        return JSON.parse(status);
    }
}