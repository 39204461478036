export function dateHandler(currentState: any, action: any) {
    switch (action.type) {
        case 'setDate':
            const nextState = { ...currentState };
            nextState.date = action.value;
            return nextState;
            break;
        default:
            return currentState;
    }
}