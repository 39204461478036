export function compteurHandler(currentState: any, action: any) {
    switch (action.type) {
        case 'timer':
            const nextState = { ...currentState };
            nextState.tempsRestant = action.value;
            return nextState;
            break;
        case 'battleStatus':
            const nextState2 = { ...currentState };
            nextState2.battleStatus = action.value;
            return nextState2;
            break;
        case 'allBattleStatus':
            const nextState3 = { ...currentState };
            nextState3.allBattleStatus = action.value;
            return nextState3;
            break;
        default:
            return currentState;
    }
}