import * as __au2ViewDef from './popup.html';
import {bindable, customElement, IDisposable, ILogger} from "aurelia";
import {TimerService} from "../services/TimerService";
import {StatusService} from "../services/status-service";
import {IEventAggregator} from "@aurelia/kernel";
import {
    BatailleEvent,
    BatailleEventAction,
    EventChannels,
    JoueurEventAction,
    PopupEvent,
    PopupEventAction
} from "../../interfaces/events";
import {Alert} from "../../interfaces/alert";

import city3 from '../../img/city-3.jpg';
import rocket1 from '../../img/rocket-1.png';
import rocket2 from '../../img/rocket-2.png';
import city4 from '../../img/city-4.jpg';

import building from '../../img/ico-building.svg';
import season from '../../img/ico-season.svg';
import group from '../../img/ico-group.svg';
import meter from '../../img/ico-meter.svg';

import { INode } from '@aurelia/runtime-html';
import {AlertService} from "../services/AlertService";
import {JoueurService} from "../services/JoueurService";
import {Joueur} from "../../interfaces/joueur";
import {StorageService} from "../services/StorageService";

@customElement({ ...__au2ViewDef, name: 'popup' })
export class Popup {

    public imgBg = '';
    public subscription: IDisposable;
    public battleSubscription: IDisposable;

    public alert: Alert|null = null;

    public bgImg : any;
    public rocket1: any;
    public rocket2: any;

    public step = 0;

    public building: any;
    public season: any;
    public group: any;
    public meter: any;

    public actions: any;

    @bindable() public answer = '';
    public answers: string[] = [];

    public bonusPossiblePoints = {
        win:0,
        loss:0
    };

    public answerChanged(newValue: string, oldValue: string) {
        this.logger.info('Answer changed', newValue);
        if (newValue !== '') {
            this.quizFormClass = 'quiz__form--line-2';
        }
    };

    public answersChanged() {
        this.logger.info('Answers changed', this.answers);
        if (this.answers.length > 0) {
            this.quizFormClass = 'quiz__form--line-2';
        } else {
            this.quizFormClass = 'quiz__form--line-1';
        }
    };

    public actionStep: any;
    public currentAction: any;

    public goodAnswer = '';
    public goodAnswers: string[] = [];

    public wrongAnswer = '';
    public wrongAnswers: string[] = [];


    public wrong = false;
    public right = false;
    public bonusHalfRight = false;
    public quizForm: HTMLElement|null = null;

    public actionBonus: any;
    public bonusStep = 0;

    public joueur: Joueur|null = null;

    public quizFormClass = 'quiz__form--line-1';

    public totalEco = 0;
    public totalConfort = 0;
    public totalPlanete = 0;

    public fakeAlertId:any;
    public teamPopup = false;

    public constructor(
        @ILogger private readonly logger: ILogger,
        @IEventAggregator private readonly ea: IEventAggregator,
        @INode private readonly element: HTMLElement,
        private readonly joueurService:JoueurService,
        private readonly statusService:StatusService,
        private readonly alertService:AlertService,
        private readonly storageService:StorageService
    ) {
        this.subscription = this.ea.subscribe(EventChannels.POPUP, this.onAlertEvent);
        this.battleSubscription = this.ea.subscribe(EventChannels.BATAILLE, this.onBatailleEvent);

        this.joueur = this.storageService.getPlayer();
        this.building = building;
        this.season = season;
        this.group = group;
        this.meter = meter;
        this.rocket1 = rocket1;
        this.rocket2 = rocket2;
    }

    public attached()
    {

    }

    public onBatailleEvent = (event: BatailleEvent) => {
        if (event.action === BatailleEventAction.READY) {
            this.closePopup();
        }
    }

    public onAlertEvent = (event: PopupEvent) => {
        if (event.action === PopupEventAction.OPEN) {
            if (event.item && event.item['alert'] && event.item['fakeAlertId']) {
                this.alert = event.item['alert'];
                this.fakeAlertId = event.item['fakeAlertId'];
                if (this.alert) {
                    switch (this.alert.bgImg) {
                        case 'city3':
                            this.bgImg = city3;
                            break;
                        case 'city4':
                            this.bgImg = city4;
                            break;
                        default:
                            this.bgImg = city3;
                            break;
                    }
                    const playerResponsesStored = this.storageService.getPlayerResponses();
                    if (playerResponsesStored) {
                        if (playerResponsesStored[this.alert.id]) {
                            const currentAlert = playerResponsesStored[this.alert.id];
                            if (currentAlert['actions'].length > 0) {
                                this.alertService.getActions(this.alert.id)
                                    .then((data: any) => {
                                        this.actions = data['actions'];
                                        this.actionBonus = data['bonus'];
                                        this.step = 1;
                                        if (currentAlert['actions'].length === this.actions.length && this.actionBonus) {
                                            this.resetAction();
                                            this.currentAction = this.actionBonus;
                                            this.bonusStep = 1;
                                            if (this.alert) {
                                                const playerResponsesStored = this.storageService.getPlayerResponses();

                                                const actions = playerResponsesStored[this.alert.id]['actions'];
                                                let total = 0;
                                                for (let i = 0; i < actions.length; i++) {
                                                    total += (actions[i].eco + actions[i].planete + actions[i].confort);
                                                }
                                                this.bonusPossiblePoints.win = total;
                                                this.bonusPossiblePoints.loss = total;
                                            }

                                        } else {
                                            this.actionStep = currentAlert['actions'].length;
                                            this.currentAction = this.actions[currentAlert['actions'].length];
                                        }

                                        this.element.classList.remove('no-popup');
                                        this.quizForm = this.element.querySelector('.quiz__form');
                                    });
                            }
                        }
                    }
                    this.element.classList.remove('no-popup');
                    this.quizForm = this.element.querySelector('.quiz__form');
                }
            }
        }
    }

    public teamStatusText = 'Pas prêt';

    public onReady(event: Event)
    {
        event.preventDefault();
        if (this.joueur && this.teamStatusText !== 'Prêt') {
            this.joueurService.ready(this.joueur.id)
                .then((data:any) => {
                    this.teamStatusText = 'Prêt'
                    //@ts-ignore
                    this.statusService.battleStatus(this.joueur);
                });
        }

    }

    public onClose(event: Event)
    {
        event.preventDefault();
        if (this.fakeAlertId === 'i0' && this.joueur && this.joueur.equipe == true) {
            this.teamPopup = true;
        } else {
            this.closePopup();
        }
    }

    public closePopup()
    {
        const playerResponsesStored = this.storageService.getPlayerResponses();
        let pinClass = 'map__pin--new';
        if (this.alert && playerResponsesStored && playerResponsesStored[this.alert.id] && playerResponsesStored[this.alert.id]['actions']) {
            const actionLength = playerResponsesStored[this.alert.id]['actions'].length;
            let bonusDone = false;

            if (this.actionBonus === null) {
                bonusDone = true;
            } else if (this.actionBonus && this.bonusStep === 2 && (this.right || this.wrong)) {
                bonusDone = true;
            }
            if ((actionLength === this.actions.length) && bonusDone) {
                pinClass = 'map__pin--passed';
                this.storageService.addFakeIds(this.fakeAlertId);
                this.storageService.deleteRunningFakeId(this.fakeAlertId);
            } else {
                pinClass = 'map__pin--ongoing';
                this.storageService.addRunningFakeIds(this.fakeAlertId);
            }
        }
        this.element.classList.add('no-popup');
        this.step = 0;
        this.actionStep = 0;
        this.resetAction();
        this.ea.publish(EventChannels.POPUP, {
            action: PopupEventAction.CLOSE,
            item: {
                fakeAlertId: this.fakeAlertId,
                pinClass: pinClass
            }
        })
    }

    public onPlay(event: Event) {
        event.preventDefault();
        this.goodAnswers = [];
        this.wrongAnswers = [];
        if (this.alert) {
            this.alertService.getActions(this.alert.id)
                .then((data: any) => {
                    this.actions = data['actions'];
                    this.actionBonus = data['bonus'];
                    this.actionStep = 0;
                    this.currentAction = this.actions[0];
                    this.step = 1;
                });
        }
    }

    public onValidate(event: Event, bonus = false) {
        event.preventDefault();
        if (this.currentAction.multiple) {
            this.bonusHalfRight = false;
            if (this.answers.length > 0) {
                for (let i = 0; i < this.currentAction['reponses'].length; i++) {
                    let idString = this.currentAction['reponses'][i]['id'].toString();
                    if (this.currentAction['reponses'][i]['valeur'] == 1) {
                        this.goodAnswers.push(idString);
                        this.currentAction['reponses'][i]['class'] = 'is-right';
                    } else if (this.currentAction['reponses'][i]['valeur'] == 0) {
                        if (this.answers.includes(idString)) {
                            this.currentAction['reponses'][i]['class'] = 'is-wrong';
                        } else {
                            this.currentAction['reponses'][i]['class'] = '';
                        }
                        this.wrongAnswers.push(this.currentAction['reponses'][i]['id']);
                    }
                }

                let good = 0;
                this.goodAnswers.forEach((item:string) => {
                    if (this.answers.includes(item)) {
                        this.bonusHalfRight = true;
                        good++;
                    }
                })
                if (good === this.goodAnswers.length && this.answers.length === this.goodAnswers.length) {
                    this.right = true;
                } else {
                    this.wrong = true;
                }
                this.quizFormClass = 'quiz__form--line-3';
            }
        } else {
            if (this.answer !== '') {
                this.currentAction['reponses'].forEach((reponse: any) => {
                    if (reponse['valeur'] == 1) {
                        this.goodAnswer = reponse['id'];
                        if (reponse['id'] == this.answer) {
                            this.right = true;
                        }
                    } else if (reponse['id'] == this.answer && reponse['valeur'] == 0) {
                        this.wrong = true;
                    }
                });
            }
            this.quizFormClass = 'quiz__form--line-3';
        }

        const playerResponsesStored = this.storageService.getPlayerResponses();
        //////////////////
        if (this.alert && !bonus) {
            let currentPlayerResponses = {
                eco: this.right ? this.currentAction.eco : 0,
                planete: this.right ? this.currentAction.planete : 0,
                confort: this.right ? this.currentAction.confort : 0
            };
            let playerResponses: any = {};
            if (playerResponsesStored) {
                playerResponses = playerResponsesStored;
            }

            if (playerResponses[this.alert.id] && playerResponses[this.alert.id]['actions']) {
                playerResponses[this.alert.id]['actions'].push(currentPlayerResponses);
            } else {
                playerResponses[this.alert.id] = {};
                playerResponses[this.alert.id]['actions'] = [currentPlayerResponses];
            }

            this.storageService.storePlayerResponses(playerResponses);
        }

        if (this.actionStep + 1 === this.actions.length && !this.actionBonus) {
            this.questionDone = true;
        }

        //////////////////
        if (bonus && this.joueur && this.alert) {
            // kill points avant c'était 0.5 au lieu de 0
            const bonusValue = this.right ? 2 : 0;
            const actions = playerResponsesStored[this.alert.id]['actions'];
            let eco = 0;
            let planete = 0;
            let confort = 0;
            let total = 0;
            for (let i = 0; i < actions.length; i++) {
                eco += actions[i].eco;
                planete += actions[i].planete;
                confort += actions[i].confort;
                total += (actions[i].eco + actions[i].planete + actions[i].confort);
            }

            const ecoValue = this.right ? eco : -eco; //-(eco / 2);
            const planeteValue = this.right ? planete : -planete; // -(planete / 2);
            const confortValue = this.right ? confort : -confort; // -(confort / 2);

            const newPoints = {
                eco: ecoValue,
                planete: planeteValue,
                confort: confortValue,
            }
            this.joueurService.addBonusPoints(this.joueur.id, newPoints)
                .then((joueur: Joueur) => {
                    this.ea.publish(EventChannels.JOUEUR, {
                        action: JoueurEventAction.MAJ_POINTS,
                        item: {
                            eco: joueur.eco,
                            planete: joueur.planete,
                            confort: joueur.confort,
                            total: joueur.total
                        }
                    });
                    this.questionDone = true;
                })
        } else if (this.right && this.joueur && this.alert) {
            let body = {
                eco: this.currentAction.eco,
                planete: this.currentAction.planete,
                confort: this.currentAction.confort
            };
            this.joueurService.addPoints(this.joueur.id, body).then((data:any) => {
                this.ea.publish(EventChannels.JOUEUR, {
                    action: JoueurEventAction.ADD_POINTS,
                    item: body
                });
                /*if (this.actionBonus !== undefined && this.actionBonus !== null) {
                } else if (this.actionStep === this.actions.length - 1) {
                    this.closePopup();
                }*/
            })
        }
    }

    public questionDone = false;

    public onClickReponse() {
        if (this.quizForm) {
            this.quizForm.classList.remove('quiz__form--line-1');
            this.quizForm.classList.add('quiz__form--line-2')
        }
    }

    public resetAction()
    {
        this.right = false;
        this.wrong = false;
        this.wrongAnswer = '';
        this.goodAnswer = '';
        this.answer = '';
        this.answers = [];
        this.bonusStep = 0;
        this.questionDone = false;
        this.teamPopup = false;
        this.quizFormClass = 'quiz__form--line-1';
    }

    public onNextQuestion(event: Event) {
        event.preventDefault();
        if ((this.actionStep + 1) < (this.actions.length)) {
            this.actionStep++;
            this.currentAction = this.actions[this.actionStep];
            this.resetAction();
        } else if (this.actionBonus !== undefined && this.actionBonus !== null) {
            this.resetAction();
            this.currentAction = this.actionBonus;
            this.bonusStep = 1;
            if (this.alert) {
                const playerResponsesStored = this.storageService.getPlayerResponses();

                const actions = playerResponsesStored[this.alert.id]['actions'];
                let total = 0;
                for (let i = 0; i < actions.length; i++) {
                    total += (actions[i].eco + actions[i].planete + actions[i].confort);
                }
                this.bonusPossiblePoints.win = total;
                this.bonusPossiblePoints.loss = total;
            }

        } else {
            this.closePopup();
        }
    }

    public onValidateBonus(event: Event) {
        event.preventDefault();
    }

    public onPlayBonus(event: Event) {
        event.preventDefault();
        this.bonusStep = 2;
    }
}
    import { Metadata as $$M } from '@aurelia/metadata';
    import { ExpressionKind as $$EK } from '@aurelia/runtime';
    import { Controller as $$C, CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';

    // @ts-ignore
    const controllers = [];

    // @ts-ignore
    if (module.hot) {

    // @ts-ignore
    module.hot.accept();

    // @ts-ignore
    const hot = module.hot;

    let aurelia = hot.data?.aurelia;

    // @ts-ignore
    document.addEventListener('au-started', (event) => {aurelia= event.detail; });
    const currentClassType = Popup;

    // @ts-ignore
    const proto = Popup.prototype

    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;

    if (proto) {
      // @ts-ignore
      proto.created = function(controller) {
        // @ts-ignore
        ogCreated && ogCreated.call(this, controller);
        controllers.push(controller);
      }
    }

    // @ts-ignore
    hot.dispose(function (data) {
      // @ts-ignore
      data.controllers = controllers;
      data.aurelia = aurelia;
    });

    if (hot.data?.aurelia) {
      const newDefinition = $$CE.getDefinition(currentClassType);
      $$M.define(newDefinition.name, newDefinition, currentClassType);
      $$M.define(newDefinition.name, newDefinition, newDefinition);
      hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;

      const previousControllers = hot.data.controllers ?? [];
      if(previousControllers.length === 0) {
        // @ts-ignore
        hot.invalidate?.();
      }

      // @ts-ignore
      previousControllers.forEach(controller => {
        const values = { ...controller.viewModel };
        const hydrationContext = controller.container.get($$IHC)
        const hydrationInst = hydrationContext.instruction;

        const bindableNames = Object.keys(controller.definition.bindables);
        // @ts-ignore
        Object.keys(values).forEach(key => {
          if (bindableNames.includes(key)) {
            return;
          }
          // if there' some bindings that target the existing property
          // @ts-ignore
          const isTargettedByBinding = controller.bindings?.some(y =>
            y.ast?.$kind === $$EK.AccessScope
              && y.ast.name === key && y.targetProperty
          );
          if (!isTargettedByBinding) {
            delete values[key];
          }
        });
        const h = controller.host;
        delete controller._compiledDef;
        controller.viewModel = controller.container.invoke(currentClassType);
        controller.definition = newDefinition;
        Object.assign(controller.viewModel, values);
        if (controller._hydrateCustomElement) {
          controller._hydrateCustomElement(hydrationInst, hydrationContext);
        } else {
          controller.hE(hydrationInst, hydrationContext);
        }
        h.parentNode.replaceChild(controller.host, h);
        controller.hostController = null;
        controller.deactivate(controller, controller.parent ?? null, 0);
        controller.activate(controller, controller.parent ?? null, 0);
      });
    }
  }