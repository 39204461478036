<template>
    <header class="header sticky-header" class.bind="connexion || classement ? 'header--simple' : 'header--complex'">
        <div class="container" if.bind="connexion || classement">
            <div class="header__logo">
                <a load="/bataille-connexion" if.bind="battle">
                    <img src.bind="logoFedene" alt="Fedene" width="490" height="320">
                </a>
                <a load="/bataille-creation" if.bind="creation">
                    <img src.bind="logoFedene" alt="Fedene" width="490" height="320">
                </a>
                <a load="/connexion" if.bind="!battle && !creation">
                    <img src.bind="logoFedene" alt="Fedene" width="490" height="320">
                </a>
            </div>
            <h1 class="header__title">Dans la peau d&rsquo;un <em>technicien de maintenance</em></h1>
        </div>
        <div class="container" else>
            <div class="header__logo">
                <a load="/bataille-connexion" if.bind="joueur.equipe">
                    <img src.bind="logoFedene" alt="Fedene" width="490" height="320">
                </a>
                <a load="/connexion" else>
                    <img src.bind="logoFedene" alt="Fedene" width="490" height="320">
                </a>
            </div>
            <div class="header__stats">
                <div class="stats stats--complex stats--border">
                    <ul class="d-flex justify-center items-center">
                        <li class="d-flex flex-wrap justify-center items-center">
                            <span>Confort</span>
                            <strong innerHTML.bind="confort"></strong>
                            <redcat-svg-sprite name="comfort" width="20" height="20" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                        </li>
                        <li class="d-flex flex-wrap justify-center items-center">
                            <span>Plan&egrave;te</span>
                            <strong innerHTML.bind="planete"></strong>
                            <redcat-svg-sprite name="planet" width="22" height="20" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                        </li>
                        <li class="d-flex flex-wrap justify-center items-center">
                            <span>Eco</span>
                            <strong innerHTML.bind="eco"></strong>
                            <redcat-svg-sprite name="eco" width="28" height="20" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                        </li>
                    </ul>
                </div>
                <!-- stats -->

            </div>
            <div class="header__point d-flex justify-between items-center">
                <span>Points :</span>
                <strong innerHTML.bind="total"></strong>
            </div>
            <div class="header__timer d-flex justify-between items-center" class.bind="getTimerClass(temps)">
            <span>
              <redcat-svg-sprite name="timer" width="28" height="28" aria-hidden="true" focusable="false"></redcat-svg-sprite>
            </span>
                <strong innerHTML.state="tempsRestant"></strong>
            </div>
            <!-- button class="header__button d-flex justify-center items-center" type="button" aria-label="Se d&eacute;connecter" click.trigger="logout()">
                <redcat-svg-sprite name="disconnect" width="24" height="24" aria-hidden="true" focusable="false"></redcat-svg-sprite>
            </button -->
        </div>
    </header>
</template>