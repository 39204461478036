<template>
    <div class="wrapper main-funnel">

        <site-header connexion.bind="false" joueur.bind="joueur"></site-header>
        <!-- header -->

        <main class="main">
            <div class="container">

                <div class="frame frame--h-2">
                    <div class="alert-banner" if.bind="temps">
                        Temps presque écoulé
                    </div>
                    <popup class="no-popup"></popup>
                    <div class="frame__mask frame--opacity-40" if.bind="isOpened"></div>

                    <div class="frame__timer" ref="frameTimer" class.bind="rotateCounter">

                        <div class="timer">
                            <div class="timer__number d-flex justify-center items-center" ref="timerNumber">${timingDuration}</div>
                            <div class="timer__hand"></div>
                            <div class="timer__kit"></div>
                            <div class="timer__back"></div>
                        </div>
                    </div>
                        <!-- timer -->
                    <div class="frame__image" class.bind="isOpened ? 'frame--opacity-60' : ''">
                        <div class="map">
                            <div class="map__list">
                                <a class="map__pin ${ buildFakeIdClass('i1')} map__pin--i1" href="" click.trigger="onClickPopup($event, 'i1')">
                                    <span class="map__pin__front d-flex justify-center items-center">
                                        <redcat-svg-sprite class="map__pin__check" name="check" width="18" height="14" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                        <redcat-svg-sprite class="map__pin__warning" name="warning" width="24" height="22" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    </span>
                                    <redcat-svg-sprite class="map__pin__back" name="pin" width="50" height="68" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                </a>
                                <a class="map__pin ${ buildFakeIdClass('i2')} map__pin--i2" href="" click.trigger="onClickPopup($event, 'i2')">
                                    <span class="map__pin__front d-flex justify-center items-center">
                                        <redcat-svg-sprite class="map__pin__check" name="check" width="18" height="14" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                        <redcat-svg-sprite class="map__pin__warning" name="warning" width="24" height="22" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    </span>
                                    <redcat-svg-sprite class="map__pin__back" name="pin" width="50" height="68" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                </a>
                                <a class="map__pin ${ buildFakeIdClass('i3')} map__pin--i3" href="" click.trigger="onClickPopup($event, 'i3')">
                                    <span class="map__pin__front d-flex justify-center items-center">
                                        <redcat-svg-sprite class="map__pin__check" name="check" width="18" height="14" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                        <redcat-svg-sprite class="map__pin__warning" name="warning" width="24" height="22" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    </span>
                                    <redcat-svg-sprite class="map__pin__back" name="pin" width="50" height="68" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                </a>
                                <a class="map__pin ${ buildFakeIdClass('i4')} map__pin--i4" href="" click.trigger="onClickPopup($event, 'i4')">
                                    <span class="map__pin__front d-flex justify-center items-center">
                                        <redcat-svg-sprite class="map__pin__check" name="check" width="18" height="14" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                        <redcat-svg-sprite class="map__pin__warning" name="warning" width="24" height="22" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    </span>
                                    <redcat-svg-sprite class="map__pin__back" name="pin" width="50" height="68" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                </a>
                                <a class="map__pin ${ buildFakeIdClass('i5')} map__pin--i5" href="" click.trigger="onClickPopup($event, 'i5')">
                                    <span class="map__pin__front d-flex justify-center items-center">
                                        <redcat-svg-sprite class="map__pin__check" name="check" width="18" height="14" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                        <redcat-svg-sprite class="map__pin__warning" name="warning" width="24" height="22" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    </span>
                                    <redcat-svg-sprite class="map__pin__back" name="pin" width="50" height="68" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                </a>
                                <a class="map__pin ${ buildFakeIdClass('i6')} map__pin--i6" href="" click.trigger="onClickPopup($event, 'i6')">
                                    <span class="map__pin__front d-flex justify-center items-center">
                                        <redcat-svg-sprite class="map__pin__check" name="check" width="18" height="14" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                        <redcat-svg-sprite class="map__pin__warning" name="warning" width="24" height="22" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    </span>
                                    <redcat-svg-sprite class="map__pin__back" name="pin" width="50" height="68" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                </a>
                                <a class="map__pin ${ buildFakeIdClass('i7')} map__pin--i7" href="" click.trigger="onClickPopup($event, 'i7')">
                                    <span class="map__pin__front d-flex justify-center items-center">
                                        <redcat-svg-sprite class="map__pin__check" name="check" width="18" height="14" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                        <redcat-svg-sprite class="map__pin__warning" name="warning" width="24" height="22" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    </span>
                                    <redcat-svg-sprite class="map__pin__back" name="pin" width="50" height="68" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                </a>
                                <a class="map__pin ${ buildFakeIdClass('i8')} map__pin--i8" href="" click.trigger="onClickPopup($event, 'i8')">
                                    <span class="map__pin__front d-flex justify-center items-center">
                                        <redcat-svg-sprite class="map__pin__check" name="check" width="18" height="14" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                        <redcat-svg-sprite class="map__pin__warning" name="warning" width="24" height="22" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    </span>
                                    <redcat-svg-sprite class="map__pin__back" name="pin" width="50" height="68" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                </a>
                                <a class="map__pin ${ buildFakeIdClass('i9')} map__pin--i9" href="" click.trigger="onClickPopup($event, 'i9')">
                                    <span class="map__pin__front d-flex justify-center items-center">
                                        <redcat-svg-sprite class="map__pin__check" name="check" width="18" height="14" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                        <redcat-svg-sprite class="map__pin__warning" name="warning" width="24" height="22" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    </span>
                                    <redcat-svg-sprite class="map__pin__back" name="pin" width="50" height="68" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                </a>
                                <a class="map__pin ${ buildFakeIdClass('i10')} map__pin--i10" href="" click.trigger="onClickPopup($event, 'i10')">
                                    <span class="map__pin__front d-flex justify-center items-center">
                                        <redcat-svg-sprite class="map__pin__check" name="check" width="18" height="14" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                        <redcat-svg-sprite class="map__pin__warning" name="warning" width="24" height="22" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    </span>
                                    <redcat-svg-sprite class="map__pin__back" name="pin" width="50" height="68" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                </a>
                                <img class="w-full h-full object-cover" src.bind="city2" alt="Ville" width="1140" height="670">
                            </div>
                        </div>
                        <!-- map -->

                    </div>
                </div>
                <!-- frame -->

            </div>
        </main>
        <!-- main -->

        <site-footer></site-footer>
        <!-- footer -->

    </div>
    <!-- wrapper -->
    <!-- footer -->
</template>