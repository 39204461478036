import {ILogger} from "aurelia";
import { HttpClientConfiguration, IHttpClient } from '@aurelia/fetch-client';
import {Joueur} from "../../interfaces/joueur";
import {Alert} from "../../interfaces/alert";

export class AlertService {

    private endpoint = '/alerts';

    public constructor(
        @IHttpClient private readonly httpClient: IHttpClient
    )
    {
        this.httpClient.configure((config: HttpClientConfiguration) => {
            config.withDefaults({
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
                credentials: 'include'
            });
            return config;
        });
    }

    public getAll(): Promise<[]>
    {
        return this.httpClient.fetch(this.endpoint, {
            method: 'GET',
        })
            .then((response:Response) => {
                return response.json();
            });
    }

    public getActions(alertId:any): Promise<[]>
    {
        return this.httpClient.fetch(this.endpoint + '/' + alertId + '/actions', {
            method: 'GET',
        })
            .then((response:Response) => {
                return response.json();
            });
    }
}