export const initialState = {
    count: 0,
    date: '',
    tempsRestant:'00:00',
    status: false,
    total: 0,
    eco: 0,
    confort:0,
    planete:0,
    live: null
};