import * as __au2ViewDef from './accueil.html';
import {IEventAggregator, ILogger} from '@aurelia/kernel';
import { INode, customElement } from '@aurelia/runtime-html';
import {
    IRouteableComponent,
    IRouter,
} from "@aurelia/router";

import {JoueurService} from '../services/JoueurService';
import {Joueur} from "../../interfaces/joueur";
import {AlertService} from "../services/AlertService";
import {Alert} from "../../interfaces/alert";
import {
    BatailleEvent, BatailleEventAction,
    EventChannels,
    JoueurEvent,
    JoueurEventAction,
    PopupEvent,
    PopupEventAction
} from "../../interfaces/events";

import city2 from '../../img/city-2.jpg';
import {IDisposable, IPlatform} from "aurelia";
import {TimerService} from "../services/TimerService";
import {StatusService} from "../services/status-service";
import {StorageService} from "../services/StorageService";
import {fromState} from "@aurelia/state";
import {alertTime} from "../state/alertTime";
@customElement(__au2ViewDef)
export class Accueil implements IRouteableComponent {

    public alertTime: number = 30;
    public email = '';
    public pseudo = '';

    public init = true;
    public city2;

    public joueur: Joueur | null = null;
    public alerts: any;

    public isOpened = true;
    //@ts-ignore
    public subscription: IDisposable;
    //@ts-ignore
    public joueurSubscription: IDisposable;
    //@ts-ignore
    public batailleSubscription: IDisposable;


    //@ts-ignore
    public frameTimer: HTMLDivElement;
    //@ts-ignore
    public timerNumber: HTMLDivElement;
    public timerMin = 0;

    public fakeIds = [];
    public runningFakeIds = [];

    @fromState(alertTime)
    public temps: boolean = false;

    constructor(
        @ILogger private readonly logger: ILogger,
        @IEventAggregator private readonly ea: IEventAggregator,
        @IRouter private router: IRouter,
        @IPlatform private readonly platform: IPlatform,
        private readonly joueurService: JoueurService,
        private readonly alertService: AlertService,
        private readonly timerService:TimerService,
        private readonly statusService:StatusService,
        private readonly storageService:StorageService
    ) {
        this.city2 = city2;
        this.joueur = this.storageService.getPlayer();
        this.fakeIds = this.storageService.getFakeIds();
        this.runningFakeIds = this.storageService.getRunningFakeIds();

     }

    public dispose() {
        if (this.subscription) {
            this.subscription.dispose();
        }
        if (this.joueurSubscription) {
            this.joueurSubscription.dispose();
        }
        if (this.batailleSubscription) {
            this.batailleSubscription.dispose();
        }
    }

    public canLoad() {
        this.logger.info('canLoad');
        if (this.storageService.getGameRunning() === false) {
            window.location.href = '/';
            return 'connexion';
        } else if (this.joueur === null) {
            window.location.href = '/';
            return 'connexion';
        }

        return true;
    }
    public bound() {
        this.logger.info('bound');

        this.subscription = this.ea.subscribe(EventChannels.POPUP, this.onAlertEvent);
        this.joueurSubscription = this.ea.subscribe(EventChannels.JOUEUR, this.onJoueurEvent);
        this.batailleSubscription = this.ea.subscribe(EventChannels.BATAILLE, this.onBatailleEvent);

    }

    public attaching() {

        this.logger.info('Joueur : ', this.joueur);

        const timerId = this.storageService.getTimer();
        if (timerId) {
            this.statusService.timer(timerId);
            this.isOpened = false;
        }
        this.alertService.getAll()
            .then((alerts: []) => {
                this.alerts = alerts;
                if (!timerId) {
                    if (this.joueur && this.joueur.equipe == true) {
                        this.ea.publish(EventChannels.POPUP, {
                            action: PopupEventAction.OPEN,
                            item: {
                                team: true,
                                alert: this.alerts['i0'],
                                fakeAlertId: 'i0'
                            }
                        });
                    } else {
                        this.ea.publish(EventChannels.POPUP, {
                            action: PopupEventAction.OPEN,
                            item: {
                                alert: this.alerts['i0'],
                                fakeAlertId: 'i0'
                            }
                        });
                    }
                }
            });
    }

    public onClickPopup(event: Event,  fakeAlertId: string)
    {
        event.preventDefault();
        this.fakeIds = this.storageService.getFakeIds();
        //@ts-ignore
        if (!this.fakeIds.includes(fakeAlertId)) {
            this.isOpened = true;
            this.ea.publish(EventChannels.POPUP, {
                action: PopupEventAction.OPEN,
                item: {
                    alert: this.alerts[fakeAlertId],
                    fakeAlertId: fakeAlertId
                }
            });
        }
    }

    public onAlertEvent = (event: PopupEvent) => {
        let fakeAlertId = null;
        if (event.action === PopupEventAction.CLOSE) {
            if (event.item) {
                fakeAlertId = event.item['fakeAlertId'];
                const pinClass = event.item['pinClass'];

                const aHtml = document.querySelector('.map__pin--' + fakeAlertId);
                if (aHtml) {
                    aHtml.classList.remove('map__pin--new');
                    aHtml.classList.remove('map__pin--ongoing');
                    aHtml.classList.remove('map__pin--passed');
                    aHtml.classList.add(pinClass);
                }

                if (fakeAlertId === 'i0' && this.joueur && this.joueur.equipe == 0) {
                    this.beginningCounter();
                }
            }
            if (fakeAlertId !== null && fakeAlertId === 'i0') {
            } else {
                this.isOpened = false;
            }
        }
    }

    public onJoueurEvent = (event: JoueurEvent) => {
        if (event.action === JoueurEventAction.FINALIZE) {
            this.storageService.setGameRunning(false);
            if (this.joueur && this.joueur.equipe) {
                this.router.load('bataille-podium');
            } else {
                this.router.load('resultats');
            }
        }
    }

    public onBatailleEvent = (event: BatailleEvent) => {
        if (event.action === BatailleEventAction.READY) {
            this.beginningCounter(false);
        }
    }

    public timingDuration = 5;
    public beginningCounter(solo = true)
    {
        let timerCounter: number;
        let timerInterval: number;
        let frameTimerActive = 'frame__timer--active';
        if (this.frameTimer && this.timerNumber) {
            this.frameTimer.classList.add(frameTimerActive);
            this.platform.taskQueue.queueTask(() => {
                //@ts-ignore
                timerInterval = this.platform.setInterval(() => {
                    this.timingDuration--;
                    // timerCounter = timerCounter - 1;
                    // this.timerNumber.textContent = timerCounter.toString();
                    /**/
                    if (this.timingDuration <= this.timerMin && this.joueur) {
                        //@ts-ignore
                        this.platform.clearInterval(timerInterval);
                        this.logger.info('Completed!');
                        this.frameTimer.classList.remove(frameTimerActive);
                        this.timerService.start(solo, this.joueur.id)
                            .then((data:any) => {
                                if (data) {
                                    this.statusService.timer(data['timerId']);
                                    this.storageService.storeTimer(data['timerId']);
                                    this.isOpened = false;
                                }
                            });
                    }
                    /**/
                }, 1e3);
            });
        }
    }

    public buildFakeIdClass(fakeAlertId: string) {
        //@ts-ignore
        if (this.fakeIds.includes(fakeAlertId)) {
            return 'map__pin--passed';
        }
        //@ts-ignore
        if (this.runningFakeIds.includes(fakeAlertId)) {
            return 'map__pin--ongoing';
        }
        return 'map__pin--new';
    }
}
    import { Metadata as $$M } from '@aurelia/metadata';
    import { ExpressionKind as $$EK } from '@aurelia/runtime';
    import { Controller as $$C, CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';

    // @ts-ignore
    const controllers = [];

    // @ts-ignore
    if (module.hot) {

    // @ts-ignore
    module.hot.accept();

    // @ts-ignore
    const hot = module.hot;

    let aurelia = hot.data?.aurelia;

    // @ts-ignore
    document.addEventListener('au-started', (event) => {aurelia= event.detail; });
    const currentClassType = Accueil;

    // @ts-ignore
    const proto = Accueil.prototype

    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;

    if (proto) {
      // @ts-ignore
      proto.created = function(controller) {
        // @ts-ignore
        ogCreated && ogCreated.call(this, controller);
        controllers.push(controller);
      }
    }

    // @ts-ignore
    hot.dispose(function (data) {
      // @ts-ignore
      data.controllers = controllers;
      data.aurelia = aurelia;
    });

    if (hot.data?.aurelia) {
      const newDefinition = $$CE.getDefinition(currentClassType);
      $$M.define(newDefinition.name, newDefinition, currentClassType);
      $$M.define(newDefinition.name, newDefinition, newDefinition);
      hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;

      const previousControllers = hot.data.controllers ?? [];
      if(previousControllers.length === 0) {
        // @ts-ignore
        hot.invalidate?.();
      }

      // @ts-ignore
      previousControllers.forEach(controller => {
        const values = { ...controller.viewModel };
        const hydrationContext = controller.container.get($$IHC)
        const hydrationInst = hydrationContext.instruction;

        const bindableNames = Object.keys(controller.definition.bindables);
        // @ts-ignore
        Object.keys(values).forEach(key => {
          if (bindableNames.includes(key)) {
            return;
          }
          // if there' some bindings that target the existing property
          // @ts-ignore
          const isTargettedByBinding = controller.bindings?.some(y =>
            y.ast?.$kind === $$EK.AccessScope
              && y.ast.name === key && y.targetProperty
          );
          if (!isTargettedByBinding) {
            delete values[key];
          }
        });
        const h = controller.host;
        delete controller._compiledDef;
        controller.viewModel = controller.container.invoke(currentClassType);
        controller.definition = newDefinition;
        Object.assign(controller.viewModel, values);
        if (controller._hydrateCustomElement) {
          controller._hydrateCustomElement(hydrationInst, hydrationContext);
        } else {
          controller.hE(hydrationInst, hydrationContext);
        }
        h.parentNode.replaceChild(controller.host, h);
        controller.hostController = null;
        controller.deactivate(controller, controller.parent ?? null, 0);
        controller.activate(controller, controller.parent ?? null, 0);
      });
    }
  }