export function liveHandler(currentState: any, action: any) {
    switch (action.type) {
        case 'live':
            const nextState = { ...currentState };
            nextState.live = action.value;
            return nextState;
            break;
        default:
            return currentState;
    }
}