import {ILogger, IEventAggregator, IPlatform, all} from "aurelia";
import {IState, IStore} from "@aurelia/state";
import {BatailleEventAction, EventChannels, JoueurEventAction} from "../../interfaces/events";
import {StorageService} from "./StorageService";
import {JoueurService} from "./JoueurService";
import {Joueur} from "../../interfaces/joueur";
export class StatusService {
    private worker: Worker;
    public constructor(
        //@ts-ignore
        @ILogger private readonly logger: ILogger,
        //@ts-ignore
        @IStore readonly store: IStore<any>,
        @IPlatform private readonly platform:IPlatform,
        @IEventAggregator private readonly ea: IEventAggregator,
        private readonly joueurService:JoueurService
    ) {
        this.logger = logger.scopeTo('StatusService');
        //@ts-ignore
        const sw = new URL('sw.js', import.meta.url);
        this.worker = new Worker(sw);
        const url = this.platform.window.location.protocol + '//' + this.platform.window.location.host + '/';
        this.logger.warn('url', url);
        this.platform.taskQueue.queueTask(() => {
            this.worker.postMessage(['init', url])
            this.worker.addEventListener('message', this.onWorkerMessage);
            this.start();
        }, { delay: 100});

    }
    public countUp() {
        this.logger.warn('countUp');
        this.worker.postMessage('countUp');
    }
    public countDown() {
        this.logger.warn('countDown');
        this.worker.postMessage('countDown');
    }
    public ping() {
        this.logger.warn('ping');
        this.worker.postMessage('ping');
    }
    public start() {
        this.logger.warn('start');
    }
    public battleStarted(batailleId: any) {
        this.worker.postMessage(['battleStarted', batailleId]);
    }
    public battleStartedStop() {
        this.worker.postMessage('battleStartedStop');
    }
    public battleStatus(joueur: Joueur)
    {
        this.logger.warn('battle status');
        this.worker.postMessage(['battleStatus', joueur.batailleId, joueur.id]);
    }
    public allBattleStatus()
    {
        this.logger.warn('all battle status');
        this.worker.postMessage('allBattleStatus');
    }
    public startLive()
    {
        this.logger.warn('start live');
        this.worker.postMessage('startLive');
    }
    public stopLive()
    {
        this.logger.warn('stop live');
        this.worker.postMessage('stopLive');
    }
    public timer(timerId:any) {
        this.logger.warn('start');
        if (timerId === null) {
            this.store.dispatch({type: 'timer', value: '00:00'});
        }
        this.worker.postMessage(['timer', timerId]);
    }

    public end() {
        this.logger.warn('end');
        this.worker.removeEventListener('message', this.onWorkerMessage);
        this.worker.terminate()
    }
    public onWorkerMessage = (event: MessageEvent) => {
        if (event.data) {
            if (event.data['message'] && event.data['message'] == 'timer') {
                if (event.data.date) {
                    this.logger.warn('onWorkerMessage date', event.data.date);
                    this.store.dispatch({type: 'setDate', value: event.data.date});
                } else if (event.data['tempsRestant']) {
                    this.logger.warn('onWorkerMessage temps restant', event.data['tempsRestant']);
                    this.store.dispatch({type: 'timer', value: event.data['tempsRestant']});
                    if (event.data['tempsRestant'] == '0') {
                        this.logger.warn('onWorkerMessage no time left');
                        this.worker.postMessage(['timer', null]);
                        this.ea.publish(EventChannels.JOUEUR, {
                            action: JoueurEventAction.FINALIZE,
                            item: null
                        });
                    }
                }
            } else if (event.data['message'] && event.data['message'] == 'battleStarted' && event.data['started'] == true) {
                this.logger.warn('onWorkerMessage battle started', event.data['started']);
                this.platform.taskQueue.queueTask(() => {
                    this.ea.publish(EventChannels.BATAILLE, {
                        action: BatailleEventAction.STARTED
                    });
                });
            } else if (event.data['message'] && event.data['message'] == 'battleStatus') {
            // } else if (event.data['status']) {
                this.logger.warn('onWorkerMessage battle status', event.data['status']);
                this.store.dispatch({type: 'battleStatus', value: event.data['status']});
                if (event.data['status'] == '1') {
                    this.logger.warn('BATTLE BEGIN !!!');
                    this.worker.postMessage(['battleStatus', null, null]);
                    this.ea.publish(EventChannels.BATAILLE, {
                        action: BatailleEventAction.READY,
                        item: null
                    });
                    /*his.joueurService.getEligibility()
                        .then((data: any) => {
                            if (data == true) {
                                 this.ea.publish(EventChannels.BATAILLE, {
                                    action: BatailleEventAction.READY,
                                    item: null
                                });
                            } else {
                                window.location.href = '/bataille-connexion';
                            }
                        })*/
                } else if (event.data['status'] == '-1') {
                    window.location.href = '/bataille-connexion';
                }
            } else if (event.data['message'] && event.data['message'] == 'live') {
                const liveData = event.data;
                delete liveData['message'];
                // this.logger.warn('onWorkerMessage battle live', liveData);
                this.store.dispatch({type: 'live', value: liveData});
            } else if (event.data['message'] && event.data['message'] == 'allBattleStatus') {
                const allBattleStatus = event.data;
                this.logger.warn('onWorkerMessage all battle status', allBattleStatus);
                this.store.dispatch({type: 'allBattleStatus', value: allBattleStatus});
            }
        }
    };
}