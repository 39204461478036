export const alertTime = (state: any = {}) => {
    const t = parseInt(state.tempsRestant.replace(':', ''));
    return t < 30 && t > 0
}
export const alertTimeLive = (state: any = {}) => {
    const t = (state.live) ? state.live.remainingTime : 0;
    return t < 30 && t > 0
}
export const alertTimeReal = (state: any = {}) => {
    const tempsRestant = state.tempsRestant.split(':');
    if (tempsRestant.length === 2) {
        const minutes = parseInt(tempsRestant[0]);
        const secondes = parseInt(tempsRestant[1]);
        return minutes * 60 + secondes;
    } else {
        return 0;
    }
}
export const currentBatailleId = (state: any = {}) => {
    if (state.live && state.live.id) {
        return state.live.id
    }
    return -1
}