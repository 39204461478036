import {Alert} from "./alert";

export enum PopupEventAction {
    OPEN,
    CLOSE,
}
export interface PopupEvent {
    action: PopupEventAction,
    item: any
}

export enum JoueurEventAction {
    ADD_POINTS,
    MAJ_POINTS,
    FINALIZE
}
export interface JoueurEvent {
    action: JoueurEventAction,
    item: any
}

export enum BatailleEventAction {
    READY,
    STARTED,
}
export interface BatailleEvent {
    action: BatailleEventAction,
    item: any
}

export enum EventChannels {
    POPUP = 'popup',
    JOUEUR = 'joueur',
    BATAILLE = 'bataille'
}