<template>
    <footer class="footer" class.bind="connexion ? 'footer--large' : 'footer--small'">
        <div class="container">
            <div class="footer__logo__fedene">
                <img src.bind="logoFedene" alt="Fedene" width="600" height="163">
            </div>
            <div class="footer__logo">
                <img src.bind="logoIndustrie" alt="Avec l&rsquo;industrie" width="666" height="130">
            </div>
            <div class="footer__text">Une initiative OPCO2i pour l&rsquo;industrie en France</div>
        </div>
    </footer>
</template>