import {ILogger} from "aurelia";
import { HttpClientConfiguration, IHttpClient } from '@aurelia/fetch-client';
import {Joueur} from "../../interfaces/joueur";
import {StorageService} from "./StorageService";

export class JoueurService {

    private endpoint = '/joueur';

    public constructor(
        @IHttpClient private readonly httpClient: IHttpClient,
        private readonly storageService:StorageService,
    )
    {
        this.httpClient.configure((config: HttpClientConfiguration) => {
            config.withDefaults({
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
                credentials: 'include'
            });
            return config;
        });
    }

    public create(body: any): Promise<Joueur>
    {
        return this.httpClient.fetch(this.endpoint, {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response:Response) => {
                return response.json();
            })
            .then((joueur:Joueur) => {
                this.storageService.storePlayer(joueur);
                return joueur;
            });
    }
    public createTeam(body: any): Promise<Joueur>
    {
        return this.httpClient.fetch(this.endpoint + '/equipe' , {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response:Response) => {
                return response.json();
            })
            .then((joueur:Joueur) => {
                this.storageService.storePlayer(joueur);
                return joueur;
            });
    }
    public addPoints(joueurId:any, body: any): Promise<Joueur>
    {
        return this.httpClient.fetch(this.endpoint + '/' + joueurId + '/add-points', {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response:Response) => {
                return response.json();
            })
            .then((joueur:Joueur) => {
                this.storageService.storePlayer(joueur);
                return joueur;
            });
    }

    public addBonusPoints(joueurId:any, body: any): Promise<Joueur>
    {
        return this.httpClient.fetch(this.endpoint + '/' + joueurId + '/add-bonus-points', {
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response:Response) => {
                return response.json();
            })
            .then((joueur:Joueur) => {
                this.storageService.storePlayer(joueur);
                return joueur;
            });
    }

    public getEligibility(): Promise<string>
    {
        const joueur = this.storageService.getPlayer();
        return this.httpClient.fetch(this.endpoint + '/' + joueur.id + '/eligibility', {
            method: 'GET',
        })
            .then((response:Response) => {
                return response.json();
            });
    }

    public get(): Promise<string>
    {
        return this.httpClient.fetch(this.endpoint, {
            method: 'GET',
        })
            .then((response:Response) => {
                return response.json();
            });
    }

    public getResult(joueurId: any): Promise<string>
    {
        return this.httpClient.fetch(this.endpoint + '/' + joueurId + '/result', {
            method: 'GET',
        })
            .then((response:Response) => {
                return response.json();
            });
    }

    public getTeamResult(joueurId: any): Promise<string>
    {
        return this.httpClient.fetch(this.endpoint + '/' + joueurId + '/team-result', {
            method: 'GET',
        })
            .then((response:Response) => {
                return response.json();
            })
            .then((data:any) => {
                data.sort((a: any, b: any) => {
                    if (a.total === b.total) {
                        return 0;
                    }
                    else {
                        return (a.total > b.total) ? -1 : 1;
                    }
                });
                return data;
            });
    }

    public ready(joueurId:any): Promise<Joueur>
    {
        return this.httpClient.fetch(this.endpoint + '/' + joueurId + '/ready', {
            method: 'POST',
        })
            .then((response:Response) => {
                return response.json();
            });
    }
}