import * as __au2ViewDef from './header.html';
import {bindable, customElement, IDisposable, ILogger} from "aurelia";

import logoFedene from '../../img/logo-fedene.png';
import {Joueur} from "../../interfaces/joueur";
import {IEventAggregator} from "@aurelia/kernel";
import {EventChannels, JoueurEvent, JoueurEventAction, PopupEvent, PopupEventAction} from "../../interfaces/events";
import {StorageService} from "../services/StorageService";
import {IRouter} from "@aurelia/router";
import {StatusService} from "../services/status-service";
import {fromState} from "@aurelia/state";
import {alertTime} from "../state/alertTime";

@customElement({ ...__au2ViewDef, name: 'site-header' })
export class Header {

    public logoFedene;

    //@ts-ignore
    public subscription: IDisposable;

    @bindable() public connexion: boolean = false;
    @bindable() public battle: boolean = false;
    @bindable() public creation: boolean = false;
    @bindable() public classement: boolean = false;
    @bindable() public joueur: Joueur|null = null;

    public total= 0;
    public eco= 0;
    public confort= 0;
    public planete= 0;
    //@ts-ignore
    @fromState(alertTime)
    public temps: boolean = false;
    public constructor(
        @ILogger private readonly logger: ILogger,
        @IEventAggregator private readonly ea:IEventAggregator,
        @IRouter private router: IRouter,
        private readonly storageService:StorageService,
        private readonly statusService:StatusService,
    ) {
        this.logger = logger.scopeTo('Header');
        this.logoFedene = logoFedene;

    }
    public binding() {
        this.subscription = this.ea.subscribe(EventChannels.JOUEUR, this.onJoueurEvent);

    }
    public dispose() {
        if (this.subscription) {
            this.subscription.dispose();
        }
    }
    public attached() {
        this.logger.info('attached');
        this.logger.warn('connexion', this.connexion);
        this.logger.warn('Header joueur', this.joueur);
        this.eco = this.joueur?.eco || 0;
        this.confort = this.joueur?.confort || 0;
        this.planete = this.joueur?.planete || 0;
        this.total = this.joueur?.total || 0;
    }

    public onJoueurEvent = (event: JoueurEvent) => {
        if (event.action === JoueurEventAction.ADD_POINTS) {
            if (event.item) {
                const points = event.item;
                this.eco += parseInt(points['eco']);
                this.confort += parseInt(points['confort']);
                this.planete += parseInt(points['planete']);


                this.total = this.eco + this.confort + this.planete;

                            }
        } else if (event.action === JoueurEventAction.MAJ_POINTS) {
            if (event.item) {
                const points = event.item;
                this.eco = parseInt(points['eco']);
                this.confort = parseInt(points['confort']);
                this.planete = parseInt(points['planete']);
                this.total = parseInt(points['total']);
            }
        }

    }

    public getTimerClass(timerData: boolean) {
        // this.logger.warn('getTimerClass', timerData);
        return timerData  ? 'red' : '';
    }
    public logout()
    {
        this.storageService.deletePlayerResponse();
        this.storageService.deletePlayer();
        this.statusService.timer(null);
        this.router.load('');
    }

}
    import { Metadata as $$M } from '@aurelia/metadata';
    import { ExpressionKind as $$EK } from '@aurelia/runtime';
    import { Controller as $$C, CustomElement as $$CE, IHydrationContext as $$IHC } from '@aurelia/runtime-html';

    // @ts-ignore
    const controllers = [];

    // @ts-ignore
    if (module.hot) {

    // @ts-ignore
    module.hot.accept();

    // @ts-ignore
    const hot = module.hot;

    let aurelia = hot.data?.aurelia;

    // @ts-ignore
    document.addEventListener('au-started', (event) => {aurelia= event.detail; });
    const currentClassType = Header;

    // @ts-ignore
    const proto = Header.prototype

    // @ts-ignore
    const ogCreated = proto ? proto.created : undefined;

    if (proto) {
      // @ts-ignore
      proto.created = function(controller) {
        // @ts-ignore
        ogCreated && ogCreated.call(this, controller);
        controllers.push(controller);
      }
    }

    // @ts-ignore
    hot.dispose(function (data) {
      // @ts-ignore
      data.controllers = controllers;
      data.aurelia = aurelia;
    });

    if (hot.data?.aurelia) {
      const newDefinition = $$CE.getDefinition(currentClassType);
      $$M.define(newDefinition.name, newDefinition, currentClassType);
      $$M.define(newDefinition.name, newDefinition, newDefinition);
      hot.data.aurelia.container.res[$$CE.keyFrom(newDefinition.name)] = newDefinition;

      const previousControllers = hot.data.controllers ?? [];
      if(previousControllers.length === 0) {
        // @ts-ignore
        hot.invalidate?.();
      }

      // @ts-ignore
      previousControllers.forEach(controller => {
        const values = { ...controller.viewModel };
        const hydrationContext = controller.container.get($$IHC)
        const hydrationInst = hydrationContext.instruction;

        const bindableNames = Object.keys(controller.definition.bindables);
        // @ts-ignore
        Object.keys(values).forEach(key => {
          if (bindableNames.includes(key)) {
            return;
          }
          // if there' some bindings that target the existing property
          // @ts-ignore
          const isTargettedByBinding = controller.bindings?.some(y =>
            y.ast?.$kind === $$EK.AccessScope
              && y.ast.name === key && y.targetProperty
          );
          if (!isTargettedByBinding) {
            delete values[key];
          }
        });
        const h = controller.host;
        delete controller._compiledDef;
        controller.viewModel = controller.container.invoke(currentClassType);
        controller.definition = newDefinition;
        Object.assign(controller.viewModel, values);
        if (controller._hydrateCustomElement) {
          controller._hydrateCustomElement(hydrationInst, hydrationContext);
        } else {
          controller.hE(hydrationInst, hydrationContext);
        }
        h.parentNode.replaceChild(controller.host, h);
        controller.hostController = null;
        controller.deactivate(controller, controller.parent ?? null, 0);
        controller.activate(controller, controller.parent ?? null, 0);
      });
    }
  }