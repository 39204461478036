<template>
    <div class="frame__modal">
        <div class.bind="fakeAlertId == 'i0' && step == 0 ? 'maintitle':'quiz'">
            <button class="quiz__close" type="button" aria-label="Fermer" click.trigger="onClose($event)" if.bind="!teamPopup && fakeAlertId !== 'i0'">
                <redcat-svg-sprite name="close" width="100%" height="100%" aria-hidden="true" focusable="false"></redcat-svg-sprite>
            </button>
            <section if.bind="step === 0" class.bind="fakeAlertId == 'i0' && step == 0 ? 'intro' : 'quiz__section'">
                <header class="quiz__header quiz__header--simple">
                    <!-- div class="quiz__pin">
                      <span class="quiz__pin__front d-flex justify-center items-center">
                        <redcat-svg-sprite class="quiz__pin__warning" name="warning" width="24" height="22" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                      </span>
                        <redcat-svg-sprite class="quiz__pin__back" name="pin" width="50" height="68" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                    </div -->
                    <h2 class="quiz__title">${(fakeAlertId == 'i0' && step == 0) ? 'Le jeu va commencer' : alert.nom } <!-- ${alert.nom} --></h2>
                </header>
                <div class="quiz__body">
                    <div class="quiz__intro" if.bind="(fakeAlertId == 'i0' && step == 0)">
                        Attention vous n'avez que ${joueur.equipe == 1 ? '6': '4'} minutes pour gagner le maximum de points
                    </div>
                    <div class="quiz__intro" if.bind="fakeAlertId !== 'i0'">
                        <ul class="d-flex flex-wrap justify-between items-stretch">
                            <li>
                                <img src.bind="building" alt="Type B&acirc;timent" width="64" height="50">
                                <span>Type B&acirc;timent</span>
                                <strong>${alert.lieu}</strong>
                            </li>
                            <li>
                                <img src.bind="season" alt="Saison" width="64" height="50">
                                <span>Saison</span>
                                <strong>${alert.saison}</strong>
                            </li>
                            <li>
                                <img src.bind="group" alt="Occup&eacute; par" width="64" height="50">
                                <span>Occup&eacute; par</span>
                                <strong>${alert.population}</strong>
                            </li>
                            <li>
                                <img src.bind="meter" alt="Niveau d&rsquo;urgence" width="64" height="50">
                                <span>Niveau d&rsquo;urgence</span>
                                <strong>${alert.urgence}/5</strong>
                            </li>
                        </ul>
                    </div>
                    <section class="quiz__stats" if.bind="fakeAlertId !== 'i0'">
                        <header class="quiz__stats__header d-flex justify-center items-end">
                            <div class="quiz__stats__dot"></div>
                            <h3 class="quiz__stats__title">Points &agrave; gagner</h3>
                            <div class="quiz__stats__dot"></div>
                        </header>
                        <div class="quiz__stats__body">

                            <div class="stats stats--complex">
                                <ul class="d-flex justify-center items-center">
                                    <li class="d-flex flex-wrap justify-center items-center">
                                        <span>Confort</span>
                                        <strong>${alert.confort}</strong>
                                        <redcat-svg-sprite name="comfort" width="20" height="20" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    </li>
                                    <li class="d-flex flex-wrap justify-center items-center">
                                        <span>Plan&egrave;te</span>
                                        <strong>${alert.planete}</strong>
                                        <redcat-svg-sprite name="planet" width="22" height="20" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    </li>
                                    <li class="d-flex flex-wrap justify-center items-center">
                                        <span>Eco</span>
                                        <strong>${alert.eco}</strong>
                                        <redcat-svg-sprite name="eco" width="28" height="20" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    </li>
                                </ul>
                            </div>
                            <!-- stats -->

                        </div>
                    </section>
                    <div class="quiz__toolbar">
                            <button if.bind="(fakeAlertId == 'i0' && step == 0)" class="quiz__button quiz__button--primary" type="button" click.trigger="onPlay($event)">Se préparer pour jouer</button>
                        <button else class="quiz__button quiz__button--primary" type="button" click.trigger="onPlay($event)">Jouer</button>
                    </div>
                </div>
            </section>
            <section if.bind="step === 1 && !teamPopup" class="quiz__section">
                <header class="quiz__header quiz__header--complex">
                    <div class="quiz__pin">
                        <span class="quiz__pin__front d-flex justify-center items-center">
                            <redcat-svg-sprite class="quiz__pin__warning" name="warning" width="24" height="22" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                        </span>
                        <redcat-svg-sprite class="quiz__pin__back" name="pin" width="50" height="68" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                    </div>
                    <h2 class="quiz__title">${alert.nom}</h2>
                    <div class="quiz__stats" if.bind="bonusStep === 0 && fakeAlertId !== 'i0'">
                        <div class="stats stats--simple">
                            <ul class="d-flex justify-start items-center">
                                <li class="d-flex flex-wrap justify-center items-center">
                                    <redcat-svg-sprite name="comfort" width="20" height="20" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    <strong>${currentAction.confort}</strong>
                                </li>
                                <li class="d-flex flex-wrap justify-center items-center">
                                    <redcat-svg-sprite name="planet" width="22" height="20" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    <strong>${currentAction.planete}</strong>
                                </li>
                                <li class="d-flex flex-wrap justify-center items-center">
                                    <redcat-svg-sprite name="eco" width="28" height="20" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                                    <strong>${currentAction.eco}</strong>
                                </li>
                            </ul>
                        </div>
                        <!-- stats -->
                    </div>
                </header>
                <div class="quiz__body" if.bind="bonusStep === 0">
                    <nav class="quiz__step d-flex justify-start items-center" aria-label="breadcrumb">
                        <span>Actions</span>
                        <ol class="d-flex justify-start items-center">
                            <li class="d-flex justify-center items-center" class.bind="$index === actionStep ? 'is-active' : ''" repeat.for="action of actions">${$index + 1}</li>
                        </ol>
                    </nav>
                    <div class="quiz__form" class.bind="quizFormClass">
                        <div class="quiz__item">
                            <div class="quiz__label">
                                <span innerHTML.bind="currentAction.question"></span>
                                <small if.bind="currentAction.multiple"><br/>(Plusieurs réponses)</small>
                            </div>
                            <div class="quiz__list">
                                <ul class="d-flex flex-wrap justify-start items-start" if.bind="currentAction.multiple">
                                    <li repeat.for="reponse of currentAction.reponses">
                                        <div class="quiz__input">
                                            <input class.bind="right || wrong ? reponse.class : ''" type="checkbox" id="qI-${$index}" value.bind="reponse.id" checked.bind="answers" change.trigger="answersChanged()">
                                            <label style="height: auto; min-height: 60px;" class="d-flex justify-center items-center" for="qI-${$index}">${reponse.reponse}</label>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="d-flex flex-wrap justify-start items-start" else>
                                    <li repeat.for="reponse of currentAction.reponses">
                                        <div class="quiz__input">
                                            <input class.bind="(reponse.id == goodAnswer) ? 'is-right' : (reponse.id == answer && wrong) ? 'is-wrong' : ''" type="radio" id="rI-${$index}" value.bind="reponse.id" checked.bind="answer">
                                            <label style="height: auto; min-height: 60px;" class="d-flex justify-center items-center" for="rI-${$index}">${reponse.reponse}</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="quiz__toolbar">
                            <div class="quiz__message is-wrong" if.bind="wrong">${currentAction.multiple ? ((wrong && bonusHalfRight) ? 'Presque !' : 'Mauvaise réponse') : 'Mauvaise réponse'}</div>
                            <div class="quiz__message is-right" if.bind="right">Bonne réponse</div>
                            <button class="quiz__button quiz__button--primary" type="button" if.bind="(right || wrong) && !questionDone" click.trigger="onNextQuestion($event)">Question suivante</button>
                            <button class="quiz__button quiz__button--primary" type="button" if.bind="((answer !== '' || answers.length > 0) && !right && !wrong) && !questionDone" click.trigger="onValidate($event)">Valider</button>
                            <button class="quiz__button quiz__button--secondary" type="button" if.bind="questionDone && fakeAlertId !== 'i0'" click.trigger="onClose($event)">Retour</button>
                            <button class="quiz__button quiz__button--secondary" type="button" if.bind="questionDone && fakeAlertId === 'i0'" click.trigger="onClose($event)">Commencer</button>

                        </div>
                    </div>
                </div>
                <div class="quiz__body" if.bind="bonusStep === 1">

                    <div class="bonus bonus--intro">
                        <div class="bonus__banner"></div>
                        <div class="bonus__summary">Répondez correctement et doublez les points que vous venez de gagner, attention en cas d'erreur vous perdez tout !</div>
                        <div class="bonus__summary" style="margin-top:15px"> <span style="color:#5eb362">${bonusPossiblePoints.win} points</span> à gagner <span style="font-weight: 100; margin-left: 15px;margin-right: 15px">OU</span> <span style="color: #da3458">${bonusPossiblePoints.loss} points</span> à perdre</div>
                        <div class="bonus__toolbar">
                            <button class="quiz__button quiz__button--secondary" type="button" click.trigger="onPlayBonus($event)">Je joue !</button>
                        </div>
                    </div>
                    <!-- bonus -->

                </div>
                <div class="quiz__body" if.bind="bonusStep === 2">

                    <div class="bonus bonus--form">
                        <div class="bonus__banner">
                            <img class="w-full h-auto object-cover" src.bind="rocket2" alt="Bonus" width="360" height="220">
                        </div>
                        <!-- div class="bonus__summary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam purus nisi, dapibus non consectetur sit amet, sollicitudin non diam.</div -->
                        <div class="bonus__item">
                            <div class="quiz__label" >
                                <span innerHTML.bind="currentAction.question"></span>
                                <small if.bind="currentAction.multiple"><br/>(Plusieurs réponses)</small>
                            </div>
                            <div class="quiz__list">
                                <ul class="d-flex flex-wrap justify-start items-start" if.bind="currentAction.multiple">
                                    <li repeat.for="reponse of currentAction.reponses">
                                        <div class="quiz__input">
                                            <input class.bind="right || wrong ? reponse.class : ''" type="checkbox" id="qbI-${$index}" value.bind="reponse.id" checked.bind="answers" change.trigger="answersChanged()">
                                            <label style="height: auto; min-height: 60px;" class="d-flex justify-center items-center" for="qbI-${$index}">${reponse.reponse}</label>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="d-flex flex-wrap justify-start items-start" else>
                                    <li repeat.for="reponse of currentAction.reponses">
                                        <div class="quiz__input">
                                            <input class.bind="(reponse.id == goodAnswer) ? 'is-right' : (reponse.id == answer && wrong) ? 'is-wrong' : ''" type="radio" id="rbI-${$index}" value.bind="reponse.id" checked.bind="answer">
                                            <label style="height: auto; min-height: 60px;" class="d-flex justify-center items-center" for="rbI-${$index}">${reponse.reponse}</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="bonus__toolbar">
                            <div class="quiz__message is-wrong" if.bind="!wrong && !right">&nbsp;</div>
                            <div class="quiz__message is-wrong" if.bind="wrong">${currentAction.multiple ? ((wrong && bonusHalfRight) ? 'Presque !' : 'Mauvaise réponse') : 'Mauvaise réponse'} </div>
                            <div class="quiz__message is-right" if.bind="right">Bonne réponse</div>
                            <button class="quiz__button quiz__button--secondary" type="button" if.bind="!questionDone" click.trigger="onValidate($event, true)">Valider</button>
                            <button class="quiz__button quiz__button--secondary" type="button" if.bind="questionDone" click.trigger="onClose($event)">Retour</button>
                        </div>
                    </div>
                    <!-- bonus -->

                </div>
            </section>
            <section if.bind="teamPopup" class="quiz__section">
                <header class="quiz__header quiz__header--simple">
                    <div class="quiz__pin">
                        <span class="quiz__pin__front d-flex justify-center items-center">
                            <redcat-svg-sprite class="quiz__pin__warning" name="warning" width="24" height="22" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                        </span>
                        <redcat-svg-sprite class="quiz__pin__back" name="pin" width="50" height="68" aria-hidden="true" focusable="false"></redcat-svg-sprite>
                    </div>
                    <h2 class="quiz__title">En attente du début de la bataille</h2>
                </header>
                <div class="quiz__body">
                    <div class="quiz__intro">
                        <ul class="d-flex flex-wrap justify-between items-stretch">
                            <li style="min-height: 80px">
                                <strong innerHTML.bind="teamStatusText" style.bind="teamStatusText !== 'Prêt' ? 'color: #da3458' : ''"></strong>
                            </li>
                        </ul>
                    </div>
                    <div class="quiz__toolbar">
                        <button if.bind="teamStatusText !== 'Prêt'" class="quiz__button quiz__button--primary" type="button" click.trigger="onReady($event)">Je suis prêt</button>
                        <span else>En attente des adversaires ...</span>
                    </div>
                </div>
            </section>
            <div class="quiz__bonus" if.bind="bonusStep === 1">
                <img class="w-full h-auto object-cover" src.bind="rocket1" alt="Bonus" width="750" height="590">
            </div>
            <div class="quiz__banner" if.bind="(fakeAlertId != 'i0' || step != 0)">
                <img class="w-full h-full object-cover" src.bind="bgImg" alt="Ville" width="240" height="560">
            </div>
        </div>
        <!-- quiz -->
    </div>

</template>