import {ILogger} from "aurelia";
import { HttpClientConfiguration, IHttpClient } from '@aurelia/fetch-client';

export class TimerService {

    private endpoint = '/timer';

    public constructor(
        @IHttpClient private readonly httpClient: IHttpClient
    )
    {
        this.httpClient.configure((config: HttpClientConfiguration) => {
            config.withDefaults({
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
                credentials: 'include'
            });
            return config;
        });
    }

    public start(solo : boolean, joueurId = null): Promise<string>
    {
        const body = solo ? {solo: true} : {solo: false, joueurId: joueurId};

        return this.httpClient.fetch(this.endpoint + '/start', {
            body: JSON.stringify(body),
            method: 'POST',
        })
            .then((response:Response) => {
                return response.json();
            });
    }
}