export function countHandler(currentState: any, action: any) {
    switch (action.type) {
        case 'increment':
            const nextState = { ...currentState };
            nextState.count++;
            return nextState;
            break;
        case 'decrement':
            const nextState2 = { ...currentState };
            nextState2.count--;
            return nextState2;
            break;
        default:
            return currentState;
    }
}