import Aurelia  from 'aurelia';
import { ConsoleSink, LoggerConfiguration, LogLevel } from '@aurelia/kernel';
import { StateDefaultConfiguration } from '@aurelia/state';
import { ValidationHtmlConfiguration } from '@aurelia/validation-html';
import { initialState } from "./app/state/initialState";
import { ToolsConfiguration } from "@redcat-au2/tools";
import svgSprites from './img/sprites.svg';
declare var webpackBaseUrl: string;
declare var __webpack_public_path__: string;
if ((window as any).webpackBaseUrl) {
    __webpack_public_path__ = webpackBaseUrl;
}
declare var PRODUCTION:boolean;

import * as globalComponents from './app/components/index';
import {App} from "./app/app";
import {RouterConfiguration, RoutingInstruction, Navigation} from "@aurelia/router";
import {countHandler} from "./app/state/countHandler";
import {dateHandler} from "./app/state/dateHandler";
import {compteurHandler} from "./app/state/compteurHandler";
import {liveHandler} from "./app/state/liveHandler";

const aurelia = Aurelia
    //@ts-ignore
    .register(RouterConfiguration.customize({
        useUrlFragmentHash: false,
        title: '${componentTitles}${appTitleSeparator}Fedene'
    }))
    .register(ValidationHtmlConfiguration)
    .register(globalComponents)
    .register(StateDefaultConfiguration.init(initialState, countHandler, dateHandler, compteurHandler, liveHandler))
    .register(ToolsConfiguration.configure({
        svgSprites: svgSprites
    }));
if(PRODUCTION == false) {

        aurelia.register(LoggerConfiguration.create({
            level: LogLevel.trace,
            colorOptions: 1,
            sinks: [ConsoleSink]
        }));
}
aurelia
    .app(App)
    .start();

